/** @jsx jsx */
import { jsx } from 'theme-ui';
import { FC } from 'react';
import { useSpring } from 'react-spring';
import CloseIcon from 'images/icons/x.svg';

import { MobileMenuWrapper, MobileMenuIcon, MobileNav } from './styled';
import MenuIcon from '../../images/icons/menu-icon.svg';

const MobileMenu: FC = ({ children }) => {
  const [animationProps, setSpring] = useSpring(() => ({
    opacity: 0,
    transform: `translateX(105%)`,
  }));
  const setOpen = (show: boolean) => {
    setSpring({
      opacity: show ? 1 : 0,
      transform: `translateX(${show ? '0px' : '105%'})`,
    });
  };

  return (
    <MobileMenuWrapper
      sx={{
        display: ['flex', 'flex', 'none'],
        alignItems: 'center',
      }}
    >
      <MobileMenuIcon>
        <button onClick={() => setOpen(true)} type="button">
          <MenuIcon />
        </button>
      </MobileMenuIcon>
      <MobileNav style={animationProps}>
        <button
          id="mobile-nav-close"
          onClick={() => setOpen(false)}
          type="button"
        >
          <CloseIcon
            sx={{
              color: 'navigationSecondaryText',
            }}
          />
        </button>
        {children}
      </MobileNav>
    </MobileMenuWrapper>
  );
};

export default MobileMenu;
