import React, { useContext, useEffect, useState } from 'react';
import * as api from 'api';
import CurrencyContext from 'context/CurrencyContext';
import ViewerContext from 'context/ViewerContext';
import { ProductName } from '../../constants/product-name';

interface SavingsProductsContextProps {
  productList: Array<Product & ExtraProductInformation>;
}

const SavingsProductsContext = React.createContext<SavingsProductsContextProps>(
  { productList: [] },
);

const projectId = process.env.APPLICATION_ID;

const IbanExtraProductInformation: ExtraProductInformation = {
  iban_account: {
    productName: 'homepage.offer.product_boxes.base.product_name',
    isAssetBacked: true,
    useShortName: false,
    hasBuybackGuarantee: true,
    hasSafeguardTrust: true,
    calculatorColor: {
      css: 'rgba(68, 176, 79, 1)',
    },
    hideInHomePage: false,
  },
  iban_one: {
    productName: 'global.product_name.one',
    isAssetBacked: true,
    useShortName: true,
    hasBuybackGuarantee: true,
    hasSafeguardTrust: true,
    calculatorColor: {
      css: '#rgba(68, 176, 79, 1)',
    },
    hideInHomePage: true,
  },
  iban_one3: {
    productName: 'homepage.offer.product_boxes.one.product_name',
    isAssetBacked: true,
    useShortName: true,
    hasBuybackGuarantee: true,
    hasSafeguardTrust: true,
    calculatorColor: {
      css: 'rgba(68, 176, 79, 0.5)',
    },
    hideInHomePage: false,
  },
  iban_market: {
    productName: 'global.product_name.market',
    isAssetBacked: true,
    useShortName: true,
    hasBuybackGuarantee: true,
    hasSafeguardTrust: true,
    calculatorColor: {
      css: 'rgba(68, 176, 79, 0.5)',
    },
    hideInHomePage: true,
  },
  iban_market4: {
    productName: 'homepage.offer.product_boxes.market.product_name',
    isAssetBacked: true,
    useShortName: true,
    hasBuybackGuarantee: true,
    hasSafeguardTrust: true,
    calculatorColor: {
      css: 'rgba(68, 176, 79, 0.3)',
    },
    hideInHomePage: false,
  },
  iban_dynamic: {
    productName: 'iban_dynamic',
    isAssetBacked: true,
    useShortName: true,
    hasBuybackGuarantee: true,
    hasSafeguardTrust: false,
    calculatorColor: undefined,
    hideInHomePage: true,
  },
  iban_30: {
    hideInHomePage: true,
    shortTerm: true,
  },
  iban_90: {
    hideInHomePage: true,
    shortTerm: true,
  },
  iban_180: {
    hideInHomePage: true,
    shortTerm: true,
  },
};

const NeowintechExtraProductInformation: ExtraProductInformation = {
  iban_account: {
    productName: 'global.product_name.account',
    isAssetBacked: true,
    useShortName: false,
    hasBuybackGuarantee: true,
    hasSafeguardTrust: true,
    calculatorColor: {
      css: '#367fff',
    },
    hideInHomePage: false,
  },
  iban_one: {
    productName: 'global.product_name.one',
    isAssetBacked: true,
    useShortName: true,
    hasBuybackGuarantee: true,
    hasSafeguardTrust: true,
    calculatorColor: {
      css: '#367fff',
    },
    hideInHomePage: false,
  },
  iban_one3: {
    productName: 'global.product_name.one',
    isAssetBacked: true,
    useShortName: true,
    hasBuybackGuarantee: true,
    hasSafeguardTrust: true,
    calculatorColor: {
      css: 'rgba(56, 85, 134, 0.4)',
    },
    hideInHomePage: false,
  },
  iban_market: {
    productName: 'global.product_name.market',
    isAssetBacked: true,
    useShortName: true,
    hasBuybackGuarantee: true,
    hasSafeguardTrust: true,
    calculatorColor: {
      css: 'rgba(56, 85, 134, 0.2)',
    },
    hideInHomePage: false,
  },
  iban_market4: {
    productName: 'global.product_name.market',
    isAssetBacked: true,
    useShortName: true,
    hasBuybackGuarantee: true,
    hasSafeguardTrust: true,
    calculatorColor: {
      css: 'rgba(56, 85, 134, 0.2)',
    },
    hideInHomePage: false,
  },
  iban_dynamic: {
    productName: 'iban_dynamic',
    isAssetBacked: true,
    useShortName: true,
    hasBuybackGuarantee: true,
    hasSafeguardTrust: false,
    calculatorColor: undefined,
    hideInHomePage: true,
  },
  iban_30: {
    hideInHomePage: true,
    shortTerm: true,
  },
  iban_90: {
    hideInHomePage: true,
    shortTerm: true,
  },
  iban_180: {
    hideInHomePage: true,
    shortTerm: true,
  },
};

export interface ExtraProductInformation {
  [key: string]: {
    productName?: string;
    isAssetBacked?: boolean;
    useShortName?: boolean;
    hasBuybackGuarantee?: boolean;
    hasSafeguardTrust?: boolean;
    calculatorColor?: {
      css: string;
    };
    hideInHomePage?: boolean;
    shortTerm?: boolean;
  };
}

export const SavingsProductsProvider: React.FC = ({ children, ...rest }) => {
  const [rawProductList, setRawProductsLis] = useState<any>();
  const { currency } = useContext(CurrencyContext);
  const { countryCode } = useContext(ViewerContext);
  const [productList, setProducts] = useState<
    (Product & ExtraProductInformation)[]
  >([]);

  const ExtraProductInformation: ExtraProductInformation =
    projectId === 'groot'
      ? IbanExtraProductInformation
      : NeowintechExtraProductInformation;

  useEffect(() => {
    let isMounted: boolean = true;

    if (!isMounted) return;

    // If already have set this tuple of values them return
    if ((rawProductList || {})[`${countryCode}-${currency}`]) return;

    // Fetch the product for the tuple
    const fetchProducts = async () => {
      const products = await api.getProducts(countryCode, currency);
      setRawProductsLis({
        ...rawProductList,
        [`${countryCode}-${currency}`]: products,
      });
    };

    fetchProducts();

    return () => {
      isMounted = false;
    };
  }, [countryCode, currency]);

  useEffect(() => {
    let isMounted: boolean = true;

    // If any of these are false/undefined/null return
    if (!isMounted || !rawProductList || !countryCode || !currency) return;

    // If there is no data yet
    if (!rawProductList[`${countryCode}-${currency}`]) return;

    const newProducts = rawProductList[`${countryCode}-${currency}`]
      ?.filter(
        (p: Product) =>
          p.name !== ProductName.STAR &&
          p.name !== ProductName.STARV4 &&
          p.name !== ProductName.STARV5 &&
          p.name !== ProductName.ANTARES &&
          p.name !== ProductName.AURA,
      )
      ?.map((product: Product) => ({
        ...ExtraProductInformation[product.name],
        interestRate: product.interestRate,
        minimumInvestment: product.minimumDepositAmount,
        years: product.termInDays ? Math.ceil(product.termInDays / 365) : null,
        compoundInterest: product.interestStrategy === 'COMPOUND',
        earlyTermination: product.penalty && +product.penalty > 0 ? true : null,
      }))
      .filter((p: Product & ExtraProductInformation) => !p.shortTerm);

    setProducts(newProducts);

    return () => {
      isMounted = false;
    };
  }, [rawProductList, countryCode, currency]);

  return (
    <SavingsProductsContext.Provider
      {...rest}
      value={{
        productList,
      }}
    >
      {children}
    </SavingsProductsContext.Provider>
  );
};

export const useProducts = () => useContext(SavingsProductsContext);
