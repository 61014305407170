/** @jsx jsx */
import { jsx, Box, Button, Flex } from 'theme-ui';
import {
  FC,
  useState,
  useRef,
  useEffect,
} from 'react';

import { useResponsiveValue } from '@theme-ui/match-media';

import ArrowIcon from '../../images/icons/arrow-icon.svg';
import GreenArrowIcon from '../../images/icons/green-arrow.svg';

interface SelectorProps {
  options: Language[];
  onChange: (newLanguage: string) => void;
  value: string;
  label: string;
  fixed: boolean;
}

const Selector: FC<SelectorProps> = ({
  options,
  onChange,
  value,
  label,
  fixed,
}) => {
  const projectId = process.env.APPLICATION_ID;
  const [open, setOpen] = useState(false);
  const containerNode = useRef<HTMLDivElement>(null);

  const containerWidthHeader = useResponsiveValue(['90px', '90px', '128px']);
  const containerWidthFooter = useResponsiveValue(['100%', '100%', '128px']);
  const optionsTopDistance = useResponsiveValue(['50px', '50px', '4rem']);
  const optionsFontSize = useResponsiveValue(['14px', '14px', '18px']);
  const containerJustifyContentHeader = useResponsiveValue(['right', 'right', 'space-between']);

  const onChangeDropDown = (option: Language['lang_iso']) => () => {
    setOpen(false);
    onChange(option);
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (containerNode?.current?.contains(e.target as Node)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  function getFlagEmoji(countryCode: string) {
    const langCode = countryCode.toUpperCase() !== 'EN' ? countryCode : 'GB';
    const codePoints = langCode
      .toUpperCase()
      .split('')
      .map(char =>  127397 + char.charCodeAt());
    return String.fromCodePoint(...codePoints);
  }

  return (
    <Flex
      ref={containerNode}
      sx={{
        alignItems: 'center',
        ml: ['auto', 'auto', 0],
        border: 'none',
        borderColor: 'none',
        borderRadius: 2,
        position: 'relative',
        minWidth: projectId === 'groot' ? ['0', '0', '0', '0', '95px'] : '0',
        width: fixed ? containerWidthFooter : containerWidthHeader
      }}
    >
      <Flex
        sx={{
          px: 6,
          py: 2,
          minWidth: '100%',
          justifyContent: fixed ? 'space-between' : containerJustifyContentHeader,
          cursor: 'pointer',
          border: '1px solid',
          borderColor: 'quaternary',
          borderRadius: 2,
        }}
        onClick={() => setOpen((val) => !val)}
      >
        <Box
          as="span"
          sx={{
            color: fixed ? 'quaternary' : 'navigationPrimaryText',
            fontSize: projectId === 'groot' ? 1 : 2
          }}
        >
          { projectId === 'groot' ? `${label} ${getFlagEmoji(label)}` : label}
        </Box>
        <Button
          sx={{
            padding: 0,
            border: 'none',
            background: 'none',
            ml: 1,
            height: '24px',
            '&:focus': { outline: 0 },
          }}
        >
          {projectId === 'groot' ? <GreenArrowIcon
            sx={{
              transform: open ? 'rotate(180deg)' : 'none',
            }}
          /> : <ArrowIcon
            sx={{
              color: fixed ? 'quaternary' : 'navigationPrimaryText',
              transform: open ? 'rotate(180deg)' : 'none',
            }}
          />}
        </Button>
      </Flex>
      <Flex
        sx={{
          color: 'quaternary',
          display: open ? 'flex' : 'none',
          position: 'absolute',
          flexDirection: 'column',
          top: fixed ? '3rem' : optionsTopDistance,
          left: 0,
          width: '100%',
          p: 0,
          m: 0,
          listStyle: 'none',
          backgroundColor: 'primaryBackground',
          borderRadius: fixed ? '0' : '4px',
          zIndex: 1,
          '& > li': {
            borderBottom: '1px solid',
            borderBottomColor: 'secondaryBackground',
            ':last-child': {
              borderBottom: 'none'
            }
          },
        }}
      >
        {options.map((option) => (
          <Button
            key={option.lang_id}
            onClick={onChangeDropDown(option.lang_iso)}
            type="button"
            sx={{
              variant: 'navigation.listItem',
              display: 'flex',
              position: 'relative',
              listStyle: 'none',
              paddingX: 6,
              width: '100%',
              background: 'transparent',
              borderTop: fixed ? 1 : '0',
              borderColor: 'secondaryBackground',
              borderStyle: 'solid',
              borderRadius: '0px',
              color:
                option.lang_iso === value
                  ? 'primary'
                  : 'navigationSecondaryText',
              fontSize: optionsFontSize,
              fontWeight: option.lang_iso === value ? 600 : 400,
              cursor: 'pointer',
              '&:hover': {
                color: 'primary',
                backgroundColor: 'secondary',
              },
              ':first-of-type': {
                borderRadius: fixed ? '0px' : '4px 4px 0px 0px'
              },
              ':last-child': {
                borderRadius: fixed ? '0px' : '0px 0px 4px 4px'
              }
            }}
          >
            {projectId === 'groot' ? `${option.lang_name} ${getFlagEmoji(option.lang_iso)}` : `${option.lang_name}`}
          </Button>
        ))}
      </Flex>
    </Flex>
  );
};

export default Selector;
