import React from 'react';
import 'components/CookieBanner/styles.css';
import CookieConsent from 'react-cookie-consent';
import theme from 'theme';
import { rem } from 'polished';
import { useIntl } from 'context/IntlContext';

interface CookieBannerProps {
  onConsent: (value: boolean) => void;
}

const CookieBanner = ({ onConsent }: CookieBannerProps) => {
  const { t } = useIntl();
  const { colors, fontWeights } = theme;
  const DAYS_TO_EXPIRE_COOKIES = 30;

  const handleAccept = () => {
    onConsent(true);
  };

  const handleDecline = () => {
    onConsent(false);
  };

  return (
    <CookieConsent
      location="bottom"
      buttonText={t('home.cookies_banner.accept')}
      enableDeclineButton
      declineButtonText={t('home.cookies_banner.reject')}
      onAccept={handleAccept}
      onDecline={handleDecline}
      buttonStyle={{
        backgroundColor: colors.primary,
        color: colors.tertiary,
        padding: '12px 24px 12px 24px',
        margin: 0,
        marginRight: '22px',
        borderRadius: '4px',
        fontWeight: fontWeights.bold,
      }}
      declineButtonStyle={{
        backgroundColor: colors.tertiary,
        border: `1px solid ${colors.primary}`,
        color: colors.primary,
        padding: '12px 24px 12px 24px',
        marginLeft: 0,
        marginRight: '22px',
        borderRadius: '4px',
        fontWeight: fontWeights.bold,
      }}
      style={{
        background: '#EDF3FC',
        boxShadow: '0px 3px 16px rgba(0, 0, 0, 0.15)',
        color: colors.primary,
        fontWeight: fontWeights.normal,
        fontSize: rem('16px'),
        lineHeight: '24px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      contentStyle={{
        margin: 0,
        color: colors.quaternary,
        opacity: 0.8,
      }}
      containerClasses="cookieContainerClass"
      expires={DAYS_TO_EXPIRE_COOKIES}
    >
      {t('home.cookies_banner.body')}
    </CookieConsent>
  );
};

export default CookieBanner;
