export default {
  colors: {
    primary: '#367FFF',
    secondary: '#EAF2FF',
    tertiary: '#FFFFFF',
    quaternary: '#233143',
    quinary: '#4F5A69',
    senary: '#DEE0E3',
    septenary: '#ADB0BA',
    success: '#31ac55',
    primaryBackground: '#FFFFFF',
    secondaryBackground: '#F7F9FC',
    tertiaryBackground: '#233143',
    quaternaryBackground: '#eaf1ff',
    quinaryBackground: '#e1ecff',

    navigationPrimaryText: '#ffffff',
    navigationSecondaryText: '#233143',
    navigationPrimaryBackground: '#233143',
    navigationSecondaryBackground: '#FFFFFF',
    modes: {
      light: {
        navigationPrimaryText: '#233143',
        navigationPrimaryBackground: '#FFFFFF',
      },
    },
  },
  fonts: {
    body: 'Barlow, sans-serif',
    heading: 'Barlow, sans-serif',
  },
  fontWeights: {
    normal: 400,
    semibold: 600,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
    title: 1.22,
    subtitle: 1.56,
    normal: 1.2,
    submenu: 1.44,
    subtitleprimary: 1.25,
    subtitlesecondary: 1.17,
    subtitletertiary: 1.26,
    textprimary: 1.63,
    comfortable: 3,
  },
  fontSizes: [
    '12px', // 0
    '14px', // 1
    '16px', // 2
    '18px', // 3
    '20px', // 4
    '24px', // 5
    '32px', // 6
    '38px', // 7
    '40px', // 8
    '46px', // 9
  ],
  space: [
    '0', // 0
    '4px', // 1
    '8px', // 2
    '12px', // 3
    '14px', // 4
    '16px', // 5
    '18px', // 6
    '24px', // 7
    '32px', // 8
    '40px', // 9
    '44px', // 10
    '48px', // 11
    '56px', // 12
    '64px', // 13
    '80px', // 14
    '128px', // 15
    '256px', // 16
    '512px', // 17
  ],
  sizes: {
    container: '1064px',
  },
  radii: ['0', '2px', '4px', '8px', '16px'],
  display: {
    none: 'none',
    inherit: 'inherit',
  },
  breakpoints: ['320px', '640px', '768px', '1024px', '1280px'],
  // Variants
  text: {
    heading: {
      fontWeight: 'semibold',
      lineHeight: 'heading',
      paddingTop: [0, 0, 0, 32, 32],
    },
    heroTitle: {
      py: 8,
      variant: 'text.heading',
      fontSize: [8, 8, 8, 9],
      lineHeight: 'heading',
    },
    sectionTitle: {
      py: 6,
      variant: 'text.heading',
      fontSize: [6, 6, 6, 7],
      color: 'quaternary',
      display: 'block',
    },
    contentTitle: {
      py: 6,
      variant: 'text.heading',
      fontSize: 3,
      color: 'quaternary',
      display: 'block',
    },
    contentTitleLight: {
      py: 3,
      variant: 'text.heading',
      fontSize: 3,
      color: 'tertiary',
      display: 'block',
    },
    content: {
      py: 2,
      fontSize: 3,
      color: 'quaternary',
    },
    underHero: {
      display: 'block',
      fontSize: [1, 1, 1, 3],
      color: 'quinary',
      fontWeight: 'normal',
    },
    underContent: {
      fontSize: 2,
      color: 'quinary',
      fontWeight: 'normal',
      display: 'block',
      textAlign: 'justify',
    },
    underContentLight: {
      fontSize: 2,
      color: 'tertiary',
      fontWeight: ['semibold', 'normal'],
      textAlign: 'justify',
    },
    smallReferences: {
      fontSize: 1,
      fontWeight: 'normal',
    },
  },
  buttons: {
    default: {
      display: 'inline-flex',
      borderRadius: 2,
      paddingTop: 3,
      paddingBottom: 4,
      paddingX: 7,
      fontSize: 3,
      lineHeight: 'subtitleprimary',
      minWidth: 'auto',
      fontFamily: 'body',
      fontWeight: 'semibold',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    primary: {
      variant: 'buttons.default',
      color: 'tertiary',
      bg: 'primary',
    },
    secondary: {
      variant: 'buttons.default',
      color: 'primary',
      bg: 'transparent',
      border: '1px',
      borderColor: 'senary',
      borderStyle: 'solid',
    },
    dark: {
      variant: 'buttons.default',
      color: 'tertiary',
      bg: 'quaternary',
    },
  },
  forms: {
    input: {
      paddingY: 5,
      paddingX: 4,
      borderRadius: 2,
      marginRight: 2,
      fontSize: 1,
      lineHeight: 'normal',
      display: 'inline-block',
      '&::placeholder': {
        fontSize: 4,
      },
    },
    select: {
      fullWidth: {
        width: '100%',
      },
    },
  },
  container: {
    px: ['20px', 5],
  },
  heading: {
    color: 'tertiary',
    fontFamily: 'heading',
    fontSize: [34, 40, 46],
    width: ['275px', '335px', '532px'],
    pl: ['20px', '20px', '151px'],
  },
  subtitle: {
    color: 'tertiary',
    fontFamily: 'heading',
    fontSize: [16, 16, 18],
    width: ['275px', '335px', '531px'],
    pl: ['20px', '20px', '151px'],
    pt: ['20px', '20px', '24px'],
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'normal',
      backgroundColor: 'primaryBackground',
    },
    a: {
      textDecoration: 'none',
      color: 'primary',
      transition: 'all 0.2s ease-in',
      '&:hover': {
        opacity: '0.8',
      },
    },
  },
};
