import React from 'react';
import NeowintechLogo from './icons/neowintech.svg';
import IbanLogo from './icons/iban.svg';

const currentBrand: Brand = process.env.APPLICATION_ID === 'groot' ? 'iban' : 'neowintech';

const Logo = ({ ...props }) => {
  switch (currentBrand) {
    case 'neowintech':
      return <NeowintechLogo {...props} />;
    case 'iban':
      return <IbanLogo {...props} />;
    default:
      return null;
  }
};

export default Logo;
