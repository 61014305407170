import React, { useState, useEffect } from 'react';
import * as api from 'api';
import { defaultData } from './constants';

const cleanUnderscoreLocale = (locale: string) => locale.replace('_', '-');

const saveLocalStorage = (c: Record<string, any>) => {
  Object.keys(c).forEach((key) => {
    localStorage.setItem(`viewer.${key}`, JSON.stringify(c[key]));
  });
};

const loadLocalStorage = (): ViewerData | undefined => {
  if (typeof window === `undefined`) return undefined;
  const copied: Record<string, ViewerData> = {};
  Object.keys(defaultData).forEach((key) => {
    const val = localStorage.getItem(`viewer.${key}`);
    if (val) copied[key] = JSON.parse(val);
  });

  if (Object.keys(defaultData).length !== Object.keys(copied).length) {
    return undefined;
  }

  return { loadedFromSession: true, ...(copied as unknown as ViewerData) };
};

const ViewerContext = React.createContext<ViewerProviderProps>(defaultData);

const ViewerProvider: React.FC = ({ children }) => {
  const [viewerData, setData] = useState<ViewerData>(defaultData);
  const [countriesList, setCountriesList] = useState<Country[] | undefined>();

  // Fetch country list
  useEffect(() => {
    const fetchCountryList = async () => {
      const list: Country[] | undefined = await api.countriesList();

      if (!list) return;

      list
        ?.filter(
          (c: Country) =>
            c.isBusinessProfileAvailable || c.isPersonalProfileAvailable,
        )
        .map((c: Country) => {
          const { allowedCurrencies, locales, countryCode, name } = c;
          const nLocal = locales.map(cleanUnderscoreLocale);
          return {
            allowedCurrencies,
            locales: nLocal,
            countryCode,
            name,
          };
        });

      setCountriesList(list);
    };

    fetchCountryList();
  }, []);

  useEffect(() => {
    if (!countriesList) return;

    // If we have the country view from the localStorage
    const localStorageViewerData = loadLocalStorage();
    
    if (localStorageViewerData) {
      setData(localStorageViewerData);
      return;
    }
    
    // Else we fetch the view country from our API
    const fecthViewerCountry = async () => {
      const country: Country | undefined = await api.viewerCountry();
      // If there isnt any data. e.g. Network Error
      // Fallback is the default language imported from const
      // Then return
      if (!country) return;
      const { locales, ...rest } = country;
      const nLocal = locales.map(cleanUnderscoreLocale);

      const data = { locales: nLocal, ...rest };
      
      saveLocalStorage(data);
      setData(data);
    };

    fecthViewerCountry();
  }, [countriesList]);

  return (
    <ViewerContext.Provider
      value={{ ...viewerData }}
    >
      {!!viewerData && children}
    </ViewerContext.Provider>
  );
};

export { ViewerProvider };
export default ViewerContext;
